import styled from "styled-components";
import { border, color, flexbox, layout, space, typography, variant } from "styled-system";
import propTypes from "@styled-system/prop-types";

import { Link } from "gatsby";

const StyledLink = styled(Link)`
  margin: 0;
  padding: 0;
  line-height: 1.3;

  cursor: pointer;
  text-decoration: underline;

  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${flexbox}
  
  &:hover {
    text-decoration: none;
  }

  ${props =>
    variant({
      variants: {
        noUnderline: {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    })}
`;

Link.displayName = `Link`;

Link.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.border,
  ...propTypes.flexbox,
};

export { StyledLink };
