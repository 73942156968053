import { MND_LINKS, GAS, ELECTRICITY } from "../constants";
import encodeUrl from "encodeurl";

const options = "toolbar=0,status=0,resizable=1,width=626,height=436";

export const formattedDate = date => {
  return new Date(date.replace(" 00:00:00.000000", "")).toLocaleDateString("cs-CZ", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const sharerWindowFB = url => {
  const sharerUrl = `https://facebook.com/sharer.php?display=popup&u=https://mnd.cz/${MND_LINKS.news}/${url}`;

  window.open(encodeUrl(sharerUrl), "sharer", options);
};

export const sharerWindowTW = (url, text) => {
  const sharerUrl = `https://twitter.com/intent/tweet?url=https://mnd.cz/${MND_LINKS.news}/${url}&text=${text}`;

  window.open(encodeUrl(sharerUrl), "sharer", options);
};

export const sharerWindowIN = url => {
  const sharerUrl = `https://www.linkedin.com/sharing/share-offsite/?url=https://mnd.cz/${MND_LINKS.news}/${url}`;

  window.open(encodeUrl(sharerUrl), "sharer", options);
};

export const inclusiveRange = (start, end, step) => {
  return Array.from(Array.from(Array(Math.ceil((end - start + 1) / step)).keys()), x => start + x * step);
};

export const isEmptyObject = obj => obj && Object.keys(obj).length === 0 && obj.constructor === Object;

export const getCommodityFromHash = hash => {
  if (hash.includes("elektrina")) {
    return ELECTRICITY;
  }
  return GAS;
};
