import React, { Fragment } from "react";

import { Flex } from "../style/Grid";
import { Text } from "../style/Text";
import { StyledLink } from "../style/Link";
import PropTypes from "prop-types";

const BreadcrumbsV2 = ({ pages, color }) => {
  return (
    <Fragment>
      {pages && (
        <Flex py="20px">
          <StyledLink color={color} fontSize="13px" to="https://mnd.cz/">
            Hlavní stránka
          </StyledLink>
          <Text as="span" px="5px" color={color} fontSize="13px">
            >
          </Text>

          {pages.map((page, index) => {
            const { link, name } = page;

            return (
              <Fragment key={index}>
                {!(index === pages.length - 1) ? (
                  <Fragment>
                    <StyledLink to={link} color={color} fontSize="13px">
                      {name}
                    </StyledLink>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Text as="span" color={color} fontSize="13px">
                      {name}
                    </Text>
                  </Fragment>
                )}

                {!(index === pages.length - 1) && (
                  <Fragment>
                    <Text as="span" px="5px" color={color} fontSize="13px">
                      >
                    </Text>
                  </Fragment>
                )}
              </Fragment>
            );
          })}
        </Flex>
      )}
    </Fragment>
  );
};

export default BreadcrumbsV2;

BreadcrumbsV2.defaultProps = {
  pages: null,
  color: "green.dark",
};

BreadcrumbsV2.propTypes = {
  pages: PropTypes.array.isRequired,
  color: PropTypes.string,
};
