import React from "react";
import loadable from "@loadable/component";

import LayoutMain from "../../components/layoutMain";
import { Wrapper, Container } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import { formattedDate } from "../../helpers";
import BreadcrumbsV2 from "../../components/breadcrumbsV2";
import { MND_LINKS } from "../../constants";
import { Helmet } from "react-helmet";
import NewsListContainer from "../../components/News/NewsListContainer";

const NewsAlert = loadable(() => import("../../components/Alert/NewsAlert"));

const pageTitle = `Novinky, články - MND`;

const NewsIndex = props => {
  const { newsPageCount, alertNewsItem, pages, nextPages, totalNews, filteredPosts: news } = props.pageContext;

  return (
    <LayoutMain>
      <Helmet>
        <title>Novinky, články - MND</title>
        <meta
          name="description"
          content={"Novinky ze světa dodávek energií a z MND. S námi budete mít vždy přehled o tom, co se právě děje. Sledujte nás také na Facebooku."}
        />
        <link rel="canonical" href={`https://www.mnd.cz/${MND_LINKS.news}`} />

        <meta property="og:url" content={`/${MND_LINKS.news}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pageTitle} />
        <meta
          property="og:description"
          content={"Novinky ze světa dodávek energií a z MND. S námi budete mít vždy přehled o tom, co se právě děje. Sledujte nás také na Facebooku."}
        />
        <script>{`
          var gaAdditionalPageData = JSON.stringify({ type: 'Articles' });
        `}</script>
        {/* TODO implementace image */}
        {/* <meta property="og:image" content={ogImg} /> */}
      </Helmet>
      <Wrapper>
        <Container>
          <BreadcrumbsV2
            pages={[
              {
                name: "Novinky",
                link: "/novinky",
              },
            ]}
            color={"grey.dark"}
          />

          <Heading as="h1" fontSize={42} color="green.dark" mb={40}>
            Novinky nejen z MND
          </Heading>
          {alertNewsItem && (
            <NewsAlert newsId={alertNewsItem.id}>
              <Text as="p" mb={3} color="grey.light" pr={50}>
                {formattedDate(alertNewsItem.releaseDate.date).toUpperCase()}
              </Text>

              <Heading as="h2" mb={3} fontSize={4} color="green.dark">
                {alertNewsItem.name}
              </Heading>

              <Text as="div">{alertNewsItem.perex}</Text>
            </NewsAlert>
          )}
          <NewsListContainer
            currentPage={props.pageContext.currentPage}
            news={news}
            nextPages={nextPages}
            totalNews={totalNews}
            newsPageCount={newsPageCount}
            pages={pages}
          />
        </Container>
      </Wrapper>
    </LayoutMain>
  );
};

export default NewsIndex;
