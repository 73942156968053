import styled from "styled-components";
import { color, flexbox, layout, position, space, system, typography } from "styled-system";
import propTypes from "@styled-system/prop-types";

const Heading = styled.h1`
  ${system({
    textTransform: true,
  })}
  margin: 0;
  padding: 0;
  ${color};
  ${flexbox};
  ${layout};
  ${position};
  ${space};
  ${typography};
`;

Heading.displayName = `Heading`;

Heading.defaultProps = {
  fontSize: 5,
  fontWeight: "700",
};

Heading.propTypes = {
  ...propTypes.color,
  ...propTypes.flexbox,
  ...propTypes.layout,
  ...propTypes.position,
  ...propTypes.space,
  ...propTypes.typography,
};

export { Heading };
